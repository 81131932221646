<template>
    <el-card class="box-card" shadow="never" style="min-height: 750px">
        <el-card shadow="never" v-if="!sucess">
            <div class="card-resul-status">
                <span class="card-result-nopass">
                    <p class="card-result-text">{{status}}</p>
                    <p class="card-result-reason" v-if="this.status === '审核中'">
                        将在1~3个工作日内完成审核，请您耐心等待
                    </p>
                    <p  v-else-if="this.status === '企业注销待审核'||this.status === '个体注销待申请'||
                        this.status === '个体注销待审核' ||this.status === '注销中'|| this.status === '已注销'
                    "></p>
                    <div v-else>
                        <p class="card-result-reason" v-if="item.status=='reject_audit'">
                            {{item.rejectReason?item.rejectReason:''}}
                        </p>
                        <p class="card-result-reason" v-else>
                            {{item.remark?item.remark:''}}
                        </p>
                    </div>

                </span>
                <span v-show="btText">
                    <el-button type="warning" size="mini" @click="dialogVisible = true">{{btText}}</el-button>
                </span>
            </div>
        </el-card>
        <el-card shadow="never" class="card-result">
            <div slot="header" class="el-card-info">
                <span>个体户信息</span>
                <el-button v-if="sucess" style="float: right; padding: 3px 0;color: #F3A26C" type="text">注册成功</el-button>
                <el-divider></el-divider>
                <div class="card-result-info">
                    <el-row >
                        <!-- 个体户名称 -->
                        <el-col :span="6" >
                            <div class="item">
                                <label>个体户名称</label>
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <div class="item">
                                <label>{{item.firmId?item.firmId:'-'}}</label>
                            </div>
                        </el-col>
                        <!-- 个体户编号 -->
                        <el-col :span="6">
                            <div class="item">
                                <label>个体户编号</label>
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <div class="item">
                                <label>{{item.firmName?item.firmName:'-'}}</label>
                            </div>
                        </el-col>
                        <!-- 营业执照号 -->
                        <el-col :span="6">
                            <div class="item">
                                <label>营业执照号</label>
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <div class="item">
                                <label>{{item.yyzzNo?item.yyzzNo:'-'}}</label>
                            </div>
                        </el-col>  
                        <!-- 服务公司 -->
                        <el-col :span="6">
                            <div class="item">
                                <label>服务公司</label>
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <div class="item">
                                <label>{{item.ocompanyName?item.ocompanyName:'-'}}</label>
                            </div>
                        </el-col>  
                        <!-- 注册时间 -->
                        <el-col :span="6">
                            <div class="item">
                                <label>注册时间</label>
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <div class="item">
                                <label>{{item.registerDate?item.registerDate:'-'}}</label>
                            </div>
                        </el-col>  
                    </el-row>
                </div>
            </div>
        </el-card>
        <el-card shadow="never" v-if="sucess">
            <div slot="header" class="card-download">
                <span>营业执照</span>
                <el-button style="float: right; padding: 3px 0;color: #F3A26C" type="text" @click="downloadIamge">下载</el-button>
            </div>
            <el-image 
            style="width: 790px; height: 500px"
            :src="url" 
            :preview-src-list="srcList">
            </el-image>
        </el-card> 
        <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        center>
        <div class="dialog">
            <span class="dialog-text">请先用微信扫描下方二维码，关注【工蚁宝】微信公众号完成个体户认证与注册</span>
            <el-image 
            class="dialog-img"
            :src="qrurl">
            </el-image>
        </div>
        </el-dialog>  
    </el-card>
</template>
<script>
import api from "@/api/list";
export default {
    data() {
        return {
            individualId: this.$route.query.individualId,
            item: '',
            sucess: false,
            dialogVisible: false,
            qrurl: require('@/assets/header_qrcode.jpg'),
            url: '',
            srcList: [],
            status: '',
            btText: ''
        };
    },
    created() {
        this.getById(this.individualId)
    },
    methods: {
        getById(id){
            api.getById({ id: id }).then((res) => {
                if (res.status == "200") {
                   this.item = res.data;
                   this.getStatus(this.item)
                   this.url = process.env.VUE_APP_FILE_PATH + this.item.yyzzPathBase;
                   this.srcList.push(this.url)
                   if(this.item.status == 'pass_audit'){
                       this.sucess = true
                   }
                }
            });
        },
        getStatus(item){
            console.log('item',item)
            if (item.status=='stay_authenticate') {
                this.status = '待认证'
                this.btText = '去认证'
            }
            if (item.status=='identity_success' || item.status=='identity_failure' || item.status=='real_failure') {
                this.status = '认证失败'
                this.btText = '去认证'
            }
            if (item.status=='real_success') {
                this.status = '待视频认证'
                this.btText = '去认证'
            }
            if (item.status=='face_success') {
                this.status = '待申请'
                this.btText = '去申请'
            }
            if (item.status=='face_failure') {
                this.status = '视频认证未通过'
                this.btText = '去认证'
            }
            if (item.status=='stay_audit' || item.status=='auditing'||item.status=='stay_government_audit') {
                this.status = '审核中'
            }
            if (item.status=='pass_audit') {
                this.status = '注册成功'
            }
            if (item.status=='reject_audit') {
                this.status = '注册失败'
                this.btText = '去认证'
            }

            //////
            if (item.status=='company_logoff_stay_audit') {
                this.status = '企业注销待审核'
            }
            if (item.status=='company_logoff_reject') {
                this.status = '企业注销驳回'
            }
            if (item.status=='individual_logoff_stay_apply') {
                this.status = '个体注销待申请'
                this.btText = '去注销'
            }
            if (item.status=='individual_logoff_stay_audit') {
                this.status = '个体注销待审核'
            }
            if (item.status=='individual_logoff_reject') {
                this.status = '个体注销驳回'
            }
            if (item.status=='logoff_ing') {
                this.status = '注销中'
            }
            if (item.status=='logoff_complete') {
                this.status = '已注销'
            }
        },
        downloadIamge() {
            //下载图片地址和图片名
            var image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
                var canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                var context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

                var a = document.createElement("a"); // 生成一个a元素
                var event = new MouseEvent("click"); // 创建一个单击事件
                a.download = "photo"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = this.url;
        }
    },
}
</script>
<style lang="less" scoped>
.box-card{
    .card-resul-status{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        .card-result-nopass{
             font-size: 14px;
            .card-result-text{
                color: #333;
                line-height: 24px;
            }
            .card-result-reason{
                color: red;
            }
        }
    }
    .card-result{
        margin-top: 20px;
        .el-card-info{
            font-size: 14px;
            color: #333;
            font-weight: 500;
            margin-left: 20px;
            width: 790px;
            .card-result-info{
                width: 400px;
                margin-top: 10px;
                .item{
                    border: 1px solid #EBEEF5;
                    text-align: center;
                    padding: 16px 0;
                    margin:0px -1px -1px 0px ;
                }
            }
        }
    }
    .card-download{
        color: #333;
        font-weight: 500;
        margin: 0 20px;
    }
    .dialog{
        text-align: center;
        .dialog-text{
        display: block;
        color: #8493FA;
        }
        .dialog-img{
        max-width: 300px; 
        max-height: 300px
        }
    }
}
</style>